<template>
    <div class="popup" :class="{ loading: loading }">
        <div v-if="postDetail && postDetail.title && postDetail.content" class="post-inner">
            <div class="gallery" ref="gallery">
                <a v-for="(image, index) in postDetail.acf['image-slide'][selectedImageIndex].gallery" :key="index" data-fancybox="gallery" :href="image">
                    <img :src="image" alt="Image" />
                </a>
            </div>
            <div class="thumbnail" ref="thumbnail">
                <div v-for="(image, index) in postDetail.acf['image-slide']" :key="index">
                    <img :src="image.detail" alt="thumbnail image" :data-key="index" @click="selectImage" :class="{ active: selectedImageIndex === index, 'initial-selected': initialSelectedImageIndex === index }" />
                </div>
            </div>
            <div class="detail" ref="detail">
                <h1>{{ postDetail.title.rendered }}</h1>
                <div v-html="filteredContent"></div>
            </div>
        </div>
        <button @click="$emit('prev-post')" class="button-prev">
            <div class="button-inner"><span class="hidden">Previous</span></div>
        </button>

        <button @click="$emit('next-post')" class="button-next">
            <div class="button-inner"><span class="hidden">Next</span></div>
        </button>
    </div>
    <button @click="handleClickClose" class="button-close">
        <span class="hidden">Close</span>
    </button>
</template>

<script>
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
    props: {
        post: Object,
    },
    computed: {
        filteredContent() {
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.postDetail.content.rendered, 'text/html');
            doc.querySelectorAll('.detail-container').forEach((el) => {
                if (!el.classList.contains(this.activeLang)) {
                    el.remove();
                }
            });
            return doc.body.innerHTML;
        },
    },
    emits: ['prev-post', 'next-post', 'close-popup'],
    data() {
        return {
            postDetail: {},
            server: 'https://vibrantwifiproject.com/wp',
            selectedImageIndex: 0,
            initialSelectedImageIndex: null,
            loading: false,
            activeLang: sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'kr',
            postType: 'posts',
        };
    },
    methods: {
        receiveLang(lang) {
            this.activeLang = lang;
        },
        initFancybox() {
            Fancybox.bind(this.$refs.gallery, '[data-fancybox="gallery"]', {
                Carousel: {
                    transition: 'slide',
                },
                Images: {
                    zoom: false,
                },
                Toolbar: {
                    display: {
                        left: [],
                        middle: [],
                        right: ['close'],
                    },
                },
                Thumbs: false,
            });
        },
        handleClickClose() {
            this.$emit('close-popup');

            this.$refs.gallery.scrollTo(0, 0);
            this.$refs.thumbnail.scrollTo(0, 0);
            this.$refs.detail.scrollTo(0, 0);
        },
        selectImage(e) {
            this.selectedImageIndex = parseInt(e.target.getAttribute('data-key'));

            this.$refs.gallery.scrollTo(0, 0);
        },
        getPostDetail() {
            if (this.post && this.post.slug) {
                this.loading = true;

                fetch(`${this.server}/wp-json/wp/v2/${this.postType}?slug=${this.post.slug}`, { method: 'GET' })
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        if (res && res.length > 0) {
                            this.selectedImageIndex = this.post.thumbnailIndex;
                            this.initialSelectedImageIndex = this.post.thumbnailIndex;

                            this.postDetail = res[0];
                        }

                        this.$nextTick(() => {
                            this.$refs.gallery.scrollTo(0, 0);
                            this.$refs.thumbnail.scrollTo(0, 0);
                            this.$refs.detail.scrollTo(0, 0);

                            this.initFancybox();

                            this.loading = false;
                        });
                    });
            }
        },
    },
    watch: {
        post: {
            immediate: true,
            deep: true,
            handler() {
                this.getPostDetail();
            },
        },
    },
    beforeMount() {
        this.emitter.on('lang', this.receiveLang);
    },
};
</script>

<style lang="scss">
@keyframes OpacityToZero {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes slideFromBottom {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes slideFromTop {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
.popup-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100 * var(--vh));
    padding: calc(4rem - 1px) 0 0;
    z-index: 100;
    background-color: #000000d9;
    display: none;
    &.open {
        display: block;
        .popup {
            animation: slideFromBottom 0.5s forwards !important;
        }
    }
    .popup {
        width: 100%;
        height: 100%;
        background-color: var(--background-color);
        transform: translateY(100%);
        &.loading {
            .post-inner {
                opacity: 0.3;
                transition: opacity 0.3s;
            }
        }
        .post-inner {
            display: grid;
            grid-template-columns: auto 250px calc(50% - 250px);
            height: 100%;
            background-color: var(--background-color);
            border-top: 1px solid var(--main-color);
            overflow: hidden;
            opacity: 1;
            transition: opacity 0.3s;
            img {
                background-color: #f9f9f9;
            }
            .gallery {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow-y: auto;
                img {
                    display: block;
                    width: 100%;
                }
            }
            .thumbnail {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow-y: auto;
                border: 1px solid var(--main-color);
                border-width: 0 1px 0;
                overflow-y: auto;
                div {
                    width: 100%;
                    img {
                        display: block;
                        // TODO
                        width: stretch;
                        // width: fill-available;
                        // width: -webkit-fill-available;
                        // width: -moz-available;
                        margin: 0.5rem 1.125rem;
                        border-radius: 1rem;
                        transition: all 0.3s;
                        object-fit: cover;
                        aspect-ratio: 1;
                        cursor: pointer;
                    }
                    // 클릭 이벤트로 전달받은 index 젤 위로 오게
                    &:has(.initial-selected) {
                        order: -1;
                        margin-top: 0.5rem;
                    }
                    &:has(.active) {
                        opacity: 0.4;
                        cursor: initial;
                        img {
                            margin: 0.5rem 0.5625rem;
                        }
                    }
                    &:hover img {
                        margin: 0.5rem 0.5625rem;
                        transition: all 0.3s;
                    }
                }
            }
            .detail {
                padding: 1.125rem;
                height: 100%;
                overflow-y: auto;
                img {
                    width: 100%;
                    height: auto;
                }
                h1 {
                    margin: 0 0 0.5rem;
                    font-size: 2.5rem;
                    font-weight: 500;
                    line-height: 1.1;
                    &.wp-block-heading {
                        margin-bottom: 1.25rem;
                        font-weight: 600;
                    }
                }
                h2 {
                    margin: 0 0 0.75rem;
                    font-size: 2.25rem;
                    font-weight: 500;
                    line-height: 1.1;
                }
                h3 {
                    margin: 0 0 0.75rem;
                    font-size: 2.25rem;
                    font-weight: 400;
                    line-height: 1.1;
                }
                .wp-block-image {
                    margin: 0 0 1.25rem;
                    figcaption {
                        margin-top: 0.3rem;
                        font-size: 0.9375rem;
                        text-align: right;
                    }
                }
                img {
                    display: block;
                    aspect-ratio: 1;
                    object-fit: cover;
                }
                p {
                    margin: 0 0 1.25rem 0;
                    font-size: 1.125rem;
                    line-height: 1.55;
                    font-weight: 500;
                }
                .wp-block-table {
                    margin: 2rem 0 2.25rem;
                    table {
                        width: 100%;
                        tr {
                            display: grid;
                            grid-template-columns: 80px auto;
                            column-gap: 0.75rem;
                            &:first-child {
                                td {
                                    border-top: 1px solid var(--main-color);
                                }
                            }
                            td {
                                padding: 0.25rem 0;
                                border-bottom: 1px solid var(--main-color);
                            }
                        }
                    }
                }
                hr {
                    margin: 1.25rem -1.125rem;
                }
            }
        }
        [class^='button'] {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 6rem;
            &.button-inner {
                width: 3rem;
                height: 100%;
                border-top: 1px solid #000;
                background-color: #0062ffcc;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 2.25rem;
                cursor: pointer;
            }
            &.button-prev {
                left: 0;
                transform: translateX(-3rem);
                transition: transform 0.3s, width 0.3s;
                &:hover {
                    width: 3rem;
                    transform: translateX(0);
                    transition: transform 0.3s, width 0.3s;
                }
                .button-inner {
                    left: 0;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTcuMTQzMyIgY3k9IjE3LjE0MjkiIHI9IjE3LjE0MjkiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMTcuMTQzMyAxNy4xNDI5KSIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTUuMzE1NjcgMTcuNDE4N0wyOC45NTMxIDE3LjQxODciIHN0cm9rZT0iIzAwNjJGRiIgc3Ryb2tlLXdpZHRoPSIxLjA1Mjg5IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTEzLjU5MTggMjUuNjgzOUw1LjMxNjA1IDE3LjQxODdMMTMuNTkxOCA5LjE0Mjk0IiBzdHJva2U9IiMwMDYyRkYiIHN0cm9rZS13aWR0aD0iMS4wNTI4OSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+Cjwvc3ZnPgo=');
                }
            }
            &.button-next {
                right: 0;
                transform: translateX(3rem);
                transition: transform 0.3s, width 0.3s;
                &:hover {
                    width: 3rem;
                    transform: translateX(0);
                    transition: transform 0.3s, width 0.3s;
                }
                .button-inner {
                    right: 0;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTcuMTQyOSIgY3k9IjE3LjE0MjkiIHI9IjE3LjE0MjkiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0yOC45NzA1IDE2Ljg2NzFMNS4zMzMwMSAxNi44NjcxIiBzdHJva2U9IiMwMDYyRkYiIHN0cm9rZS13aWR0aD0iMS4wNTI4OSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik0yMC42OTQzIDguNjAxODdMMjguOTcwMSAxNi44NjcxTDIwLjY5NDMgMjUuMTQyOCIgc3Ryb2tlPSIjMDA2MkZGIiBzdHJva2Utd2lkdGg9IjEuMDUyODkiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8L3N2Zz4K');
                }
            }
        }
    }
    .button-close {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(2rem - 50%));
        width: 2.5rem;
        height: 2.5rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ3IDEzTDEzIDQ3IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNMTMgMTNMNDcgNDciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxjaXJjbGUgY3g9IjMwIiBjeT0iMzAiIHI9IjI5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
        cursor: pointer;
    }
}
.fancybox__container {
    .fancybox__toolbar {
        opacity: 1 !important;
        .f-button[data-fancybox-close] {
            position: fixed;
            top: 0;
            left: 50%;
            transform: translate(-50%, calc(2rem - 50%));
            background-color: transparent;
            width: 2.5rem;
            height: 2.5rem;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ3IDEzTDEzIDQ3IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNMTMgMTNMNDcgNDciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxjaXJjbGUgY3g9IjMwIiBjeT0iMzAiIHI9IjI5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
            svg {
                display: none;
            }
        }
    }
    .fancybox__backdrop {
        background-color: #000000d9;
    }
    .fancybox__carousel {
        height: calc(100% - 4rem);
        margin-top: 4rem;
        width: 60vw;
        margin: 4rem auto 0;
        overflow-x: visible;
        .fancybox__track {
            align-items: center;
            .fancybox__slide {
                width: 75vh !important;
                height: 75vh !important;
                margin-right: 10vw;
                padding: 0 !important;
                transition: height 0.3s, opacity 0.3s;
                overflow: visible;
                cursor: grab;
                &.is-selected {
                    .fancybox__content {
                        transform: scale(1.15) !important;
                    }
                }
                &:not(.is-selected) {
                    opacity: 0.7;
                }
                .fancybox__content {
                    width: 100% !important;
                    height: 100% !important;
                    border-radius: 3rem;
                    cursor: grab;
                    overflow: hidden;
                    aspect-ratio: 1;
                    transition: transform 0.3s;
                    img {
                        object-fit: cover;
                        background-color: #5e5e5e;
                    }
                }
            }
        }
    }
    .fancybox__nav {
        position: fixed;
        left: 0;
        right: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        opacity: 1 !important;
        .f-button {
            top: unset !important;
            width: 6rem;
            height: 5rem;
            transform: unset !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            &:hover {
                background-color: transparent;
            }
            &.is-prev {
                left: 1.275rem;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIyIiBoZWlnaHQ9Ijg1IiB2aWV3Qm94PSIwIDAgMTIyIDg1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMi4wMDAyNSA0Mi45MDU3TDEyMS4wMjQgNDIuOTA1NyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTQzLjY3MjQgODMuOTk0NkwyLjAwMDY2IDQyLjM3NTlMNDMuNjcyNCAwLjcwNDIyNCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPC9zdmc+Cg==');
            }
            &.is-next {
                right: 1.275rem;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIxIiBoZWlnaHQ9Ijg1IiB2aWV3Qm94PSIwIDAgMTIxIDg1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTE5LjAyNCA0MS43OTMxTDAgNDEuNzkzMiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPHBhdGggZD0iTTc3LjM1MTYgMC43MDQyMzZMMTE5LjAyMyA0Mi4zMjI5TDc3LjM1MTYgODMuOTk0NiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPC9zdmc+Cg==');
            }
            svg {
                display: none;
            }
        }
    }
}
@media (max-width: 1300px) {
    .popup-wrapper {
        .popup {
            .post-inner {
                grid-template-columns: auto 220px 400px;
            }
        }
    }
}
@media (max-width: 1024px) {
    .popup-wrapper {
        padding-top: calc((100vw - 1.5rem) / (1400 / 368) / 2 + 1.5rem);
        overflow-y: auto;
        background-color: #00000099;
        .popup {
            height: auto;
            min-height: 100%;
            .post-inner {
                display: flex;
                flex-direction: column;
                height: auto;
                .thumbnail {
                    order: 0;
                    flex-direction: row;
                    padding: 1.5rem 1.125rem;
                    border: 0;
                    overflow-x: auto;
                    overflow-y: hidden;
                    div {
                        width: 9vw;
                        height: 9vw;
                        margin: 0 0.75rem 0 0 !important;
                        &:last-child {
                            margin: 0 !important;
                        }
                        img {
                            height: 100%;
                            margin: 0 !important;
                            border-radius: 0.5rem;
                        }
                    }
                }
                .gallery {
                    order: 1;
                    flex-direction: row;
                    gap: 1.125rem;
                    height: 90vw;
                    max-height: 500px;
                    padding: 0 1.125rem 2rem;
                    overflow-x: auto;
                    overflow-y: hidden;
                    a {
                        flex: none;
                        height: 100%;
                        border-radius: 1.25rem;
                        overflow: hidden;
                        aspect-ratio: 1;
                        // pointer-events: none;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                }
                .detail {
                    order: 2;
                    height: auto;
                    padding: 0 2.25rem 6rem;
                    overflow: initial;
                    h1 {
                        margin: 0 0 0.75rem;
                        font-size: 1.875rem;
                        &.wp-block-heading {
                            font-weight: 400;
                            margin-bottom: 1.875rem;
                        }
                    }
                    h2 {
                        margin: 0 0 0.75rem;
                        font-size: 1.875rem;
                    }
                    h3 {
                        margin: 0 0 1.875rem;
                        font-size: 1.875rem;
                    }
                    .wp-block-image {
                        margin: 0 0 2rem;
                        figcaption {
                            margin: 0.6rem 0.6rem 0 0;
                        }
                    }
                    img {
                        border-radius: 1.25rem;
                    }
                    p {
                        margin: 0 0 1.875rem 0;
                        font-size: 1.25rem;
                        line-height: 1.6;
                    }
                    hr {
                        margin: 1.125rem 0;
                    }
                    .profile {
                        display: flex;
                        flex-direction: column;
                        p {
                            order: 1;
                        }
                        .wp-block-table {
                            order: 2;
                            margin: 0 0 2.25rem;
                            table {
                                tr {
                                    grid-template-columns: 90px auto;

                                    td {
                                        padding: 0.4rem 0;
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }
                        .wp-block-image {
                            order: 3;
                        }
                        hr {
                            display: none;
                        }
                    }
                    .about {
                        .wp-block-heading {
                            margin-bottom: 1.5rem;
                        }
                        p {
                            margin: 0 0 1.875rem 0;
                        }
                    }
                }
            }
            .button-prev,
            .button-next {
                top: unset;
                bottom: 2rem;
                width: 30px !important;
                height: 30px !important;
                transform: unset !important;
                &.button-prev {
                    left: 2.25rem;
                    .button-inner {
                        transform: rotate(180deg);
                    }
                }
                &.button-next {
                    right: 2.25rem;
                }
                .button-inner {
                    position: relative;
                    width: 100%;
                    border-top: 0;
                    background-color: transparent;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTUiIGN5PSIxNSIgcj0iMTUiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0yNS4zNDk1IDE0Ljc1ODhMNC42NjY3NSAxNC43NTg4IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik0xOC4xMDgyIDcuNTI2NjdMMjUuMzQ5NCAxNC43NTg3TDE4LjEwODIgMjIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPC9zdmc+Cg==') !important;
                    background-size: contain;
                }
            }
        }
        .button-close {
            position: fixed;
            top: unset;
            bottom: 1.125rem;
            width: 4rem;
            height: 4rem;
            transform: translate(-50%, 0) !important;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMjQiIGN5PSIyNCIgcj0iMjMuMzgzMyIgZmlsbD0iI0RCREJEQiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjIzMzMzIi8+CjxwYXRoIGQ9Ik0zNy42MDAyIDEwLjQwMDFMMTAuNDAwMSAzNy42MDAxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuMjMzMzMiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNMTAuMzk5OCAxMC40MDAxTDM3LjU5OTkgMzcuNjAwMSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjIzMzMzIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPC9zdmc+Cg==');
        }
    }
    .fancybox__container {
        .fancybox__toolbar {
            .f-button[data-fancybox-close] {
                top: unset;
                bottom: 1.125rem;
                width: 4rem;
                height: 4rem;
                transform: translate(-50%, 0) !important;
            }
        }
        .fancybox__carousel {
            width: 100%;
            .fancybox__track {
                .fancybox__slide {
                    width: 70vw !important;
                    height: 70vw !important;
                }
            }
        }
        .fancybox__nav {
            .f-button {
                width: 3rem;
                height: 2rem;

                &.is-prev {
                    left: 0.75rem;
                }
                &.is-next {
                    right: 0.75rem;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .popup-wrapper {
        .popup {
            .post-inner {
                .thumbnail {
                    padding: 1.125rem 0.75rem;
                    div {
                        width: 12vw;
                        height: 12vw;
                        margin-right: 0.5rem !important;
                        img {
                            border-radius: 0.3125rem;
                        }
                    }
                }
                .gallery {
                    gap: 0.75rem;
                    padding: 0 0.75rem 1.5rem;
                }
                .detail {
                    padding: 0 1.5rem 5rem;
                    h1 {
                        margin-bottom: 0.25rem;
                        font-size: 1.75rem;
                        line-height: 1.25;
                        &.wp-block-heading {
                            margin-bottom: 1.5rem;
                        }
                    }
                    h2 {
                        margin-bottom: 0.25rem;
                        font-size: 1.75rem;
                        line-height: 1.25;
                    }
                    h3 {
                        margin-bottom: 1.5rem;
                        font-size: 1.75rem;
                        line-height: 1.25;
                    }
                    p {
                        font-size: 1.125rem;
                        line-height: 1.65;
                    }
                    .profile {
                        .wp-block-table {
                            table {
                                tr {
                                    grid-template-columns: 85px auto;
                                    td {
                                        padding: 0.2rem 0 0.25rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .button-prev,
            .button-next {
                &.button-prev {
                    left: 1.5rem;
                }
                &.button-next {
                    right: 1.5rem;
                }
            }
        }
    }
}
@media (max-width: 480px) {
    .popup-wrapper {
        .popup {
            .post-inner {
                .detail {
                    .profile {
                        .wp-block-table {
                            margin: 0 -1.5rem 2.25rem;
                            padding: 0 1.5rem;
                            overflow-x: auto;
                            table {
                                tr {
                                    td {
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
