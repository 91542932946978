<template>
    <div class="content-container">
        <div class="grid-box original" ref="gridBox" @scroll="updateActivePosts">
            <a
                :key="`${index}_${index}`"
                :ref="`post_${index}_${index}`"
                v-for="(image, index) in this.images"
                :href="`${image.url}`"
                data-fancybox="gallery"
                class="post-item"
                :class="{
                    'third-row-1': (index - 1) % 6 === 0,
                    'third-row-2': (index - 2) % 6 === 0,
                    'third-row-3': (index - 3) % 6 === 0,
                    'half-row-1': (index - 4) % 6 === 0,
                    'half-row-2': (index - 5) % 6 === 0,
                    'full-row': index % 6 === 0,
                    active: activePosts.includes(`${index}_${index}`),
                }">
                <div class="item-inner">
                    <div class="thumbnail-wrapper">
                        <img :src="`${image.url}`" />
                    </div>
                    <strong class="post-title" style="text-transform: capitalize">{{ image.title }}</strong>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
    name: 'AppOriginal',
    data() {
        return {
            activeLang: sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'kr',
            isMobile: false,
            targetScrollX: 0,
            currentScrollX: 0,
            isScrolling: false,
            scrollAnimationFrameID: null,
            isScrollLeftDisabled: true,
            isScrollRightDisabled: false,
            activePosts: [],
            lastScroll: 0,
            scrollCount: 0,
            scrollLimit: 100,
            mobileScrolling: false,
            images: [],
        };
    },
    methods: {
        receiveLang(lang) {
            this.activeLang = lang;
        },
        initFancybox() {
            Fancybox.bind(this.$refs.gridBox, '[data-fancybox="gallery"]', {
                Carousel: {
                    transition: 'slide',
                },
                Images: {
                    zoom: false,
                },
                Toolbar: {
                    display: {
                        left: [],
                        middle: [],
                        right: ['close'],
                    },
                },
                Thumbs: false,
            });
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth <= 1024;
        },
        handleScroll(e) {
            // 가로 스크롤
            e.preventDefault();

            const maxScrollX = this.$refs.gridBox.scrollWidth - this.$refs.gridBox.clientWidth;

            if (e.deltaY) {
                this.targetScrollX = Math.min(Math.max(this.targetScrollX + e.deltaY, 0), maxScrollX);
                if (!this.isScrolling) {
                    this.animateScroll();
                }
            }
        },
        animateScroll() {
            this.isScrolling = true;

            const difference = this.targetScrollX - this.currentScrollX;
            const toMove = difference * 0.1;

            if (Math.abs(difference) > 1) {
                this.currentScrollX += toMove;
                this.$refs.gridBox.scrollLeft = this.currentScrollX;
                this.scrollAnimationFrameID = requestAnimationFrame(this.animateScroll);
            } else {
                this.currentScrollX = this.targetScrollX;
                this.$refs.gridBox.scrollLeft = this.targetScrollX;
                this.isScrolling = false;
            }
        },
        updateScrollListener() {
            if (!this.isMobile && !this.isScrollListenerAdded) {
                this.$refs.gridBox.addEventListener('wheel', this.handleScroll, { passive: false });
                this.isScrollListenerAdded = true;
            } else if (this.isMobile && this.isScrollListenerAdded) {
                this.$refs.gridBox.removeEventListener('wheel', this.handleScroll);
                this.isScrollListenerAdded = false;
            }
        },
        debounce(func, wait, immediate) {
            let timeout;
            return function () {
                const context = this,
                    args = arguments;
                const later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                const callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },
        updateActivePosts() {
            // scroller active
            const gridBox = this.$refs.gridBox;
            this.isScrollLeftDisabled = gridBox.scrollLeft === 0;
            this.isScrollRightDisabled = gridBox.scrollLeft >= gridBox.scrollWidth - gridBox.clientWidth;
            this.$nextTick(() => {
                const activePostDelays = {
                    'third-row': [],
                    'half-row': [],
                    'full-row': [],
                };
                const delayStep = 0.1;

                let posts = [];
                for (let refName in this.$refs) {
                    if (refName.startsWith('post_')) {
                        posts.push(this.$refs[refName]);
                    }
                }

                posts.forEach((post, index) => {
                    const element = this.$refs[`post_${index}_${index}`][0];
                    if (!element) return;
                    const rect = element.getBoundingClientRect();
                    const combinedKey = `${index}_${index}`;
                    const rowClass = element.classList[1].split('-')[0];
                    if (!this.isMobile) {
                        if (rect.left >= 0 && rect.left <= window.innerWidth) {
                            if (!this.activePosts.includes(combinedKey)) {
                                this.activePosts.push(combinedKey);
                                if (!activePostDelays[rowClass]) {
                                    activePostDelays[rowClass] = [];
                                }
                                const currentIndex = activePostDelays[rowClass].length;
                                const animationDelay = currentIndex * delayStep;
                                element.style.animationDelay = `${animationDelay}s`;
                                activePostDelays[rowClass].push(animationDelay);
                            }
                        }
                    } else {
                        this.handleToggleViewByScroll(this.$refs.gridBox.scrollTop);
                        if (rect.top <= window.innerHeight) {
                            if (!this.activePosts.includes(combinedKey)) {
                                this.activePosts.push(combinedKey);
                            }
                        }
                    }
                });
            });
        },
        createIntersectionObserver() {
            // grid-box가 뷰포트에 들어올 때 애니메이션이 실행돼야함 (active 클래스)
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            };
            const $this = this;
            function callback(entries) {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // 뷰포트에 들어올때마다 다시 실행되도록 gridBox 스크롤값 및 배열 초기화
                        $this.$refs.gridBox.scrollTop = 0;
                        $this.$refs.gridBox.scrollLeft = 0;
                        $this.targetScrollX = 0;
                        $this.currentScrollX = 0;
                        $this.activePosts = [];

                        $this.updateActivePosts();

                        // swiper transition 때문에 rect.top이 늦게 반영돼서 한 번 더 실행해주기
                        if ($this.isMobile) {
                            setTimeout(() => {
                                $this.updateActivePosts();
                            }, 200);
                        }
                    }
                });
            }

            const observer = new IntersectionObserver(callback, options);
            const gridBox = this.$refs.gridBox;
            observer.observe(gridBox);
        },
        handleToggleViewByScroll(scroll) {
            let delta = scroll - this.lastScroll;

            if (delta > 0 && this.scrollCount < 0) this.scrollCount = 0;
            if (delta < 0 && this.scrollCount > 0) this.scrollCount = 0;
            this.scrollCount = Math.min(Math.max(this.scrollCount + delta, -this.scrollLimit), this.scrollLimit);

            if (this.scrollCount === this.scrollLimit && !this.mobileScrolling) {
                this.mobileScrolling = true;
            } else if (this.scrollCount === -this.scrollLimit && this.mobileScrolling) {
                this.mobileScrolling = false;
            }

            this.lastScroll = scroll;
        },
        async getPosts() {
            const server = 'https://vibrantwifiproject.com/wp';
            const postType = 'options';

            try {
                const response = await fetch(`${server}/wp-json/acf/v2/${postType}`);
                const images = await response.json();
                images['acf']['images'].forEach((image) => {
                    this.images.push(image);
                });
                this.createIntersectionObserver();
                this.initFancybox();
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        },
    },
    mounted() {
        this.getPosts();

        document.querySelector('html').dataset.page = 'original';

        this.checkScreenSize();
        this.updateScrollListener();

        window.addEventListener('resize', this.checkScreenSize);
        window.addEventListener('resize', this.debounce(this.updateScrollListener, 250));
    },
    beforeMount() {
        this.emitter.on('lang', this.receiveLang);
    },
    beforeUnmount() {
        if (this.scrollAnimationFrameID !== null) {
            cancelAnimationFrame(this.scrollAnimationFrameID);
        }

        window.removeEventListener('resize', this.checkScreenSize);
        this.$refs.gridBox.removeEventListener('wheel', this.handleScroll);
    },
};
</script>

<style lang="scss">
#content {
    .grid-box {
        .post-item {
            &.third-row-1,
            &.third-row-2,
            &.third-row-3 {
                width: 25vw;
                max-width: 400px;
            }
            &.half-row-1,
            &.half-row-2 {
                width: 35vw;
                max-width: 600px;
            }
            &.full-row {
                width: 40vw;
                max-width: 650px;
            }
        }
    }
}
@media (max-width: 1024px) {
    #content {
        .grid-box {
            .post-item {
                max-width: unset !important;
            }
        }
    }
}
</style>
