<template>
    <nav class="navigation-container" :style="{ display: handleDisplay() }">
        <div v-if="isMobile" class="mobile-toggle-button" @click="handleMobileMenu" ref="toggleButton">
            <div class="open-button"></div>
            <div class="overlay"></div>
        </div>
        <div class="navigation-wrapper top">
            <Widget v-if="!isMobile" :isMobile="isMobile" />
            <router-link to="/" class="navigation-logo" @click="notifyClick">
                <span class="hidden">vibrant wifi</span>
                <img src="@/assets/img/logo_small.svg" alt="vibrant wifi" v-if="!isMobile" class="loop" />
                <img src="@/assets/img/logo_loop.svg" alt="vibrant wifi" v-if="!isMobile" class="loop" />
                <img src="@/assets/img/logo.svg" alt="vibrant wifi" v-if="isMobile" />
            </router-link>
            <div class="language-button-wrapper" v-if="!isMobile">
                <button class="language-button" :class="{ active: activeLang === 'kr' }" @click="changeLang('kr')">KR</button>
                <button class="language-button" :class="{ active: activeLang === 'en' }" @click="changeLang('en')">EN</button>
            </div>
        </div>
        <div class="navigation-wrapper bottom" ref="toggleMenu">
            <router-link to="/vibrant-design-project" @click="handleClick()">Vibrant Design Project</router-link>
            <router-link to="/kt-wifi-6d" @click="handleClick()">KT WiFi 6D</router-link>
            <router-link to="/skin-collaboration" :class="[$route.path.startsWith('/skin-collaboration') ? 'router-link-active' : '']" @click="handleClick()">Skin Collaboration</router-link>
            <router-link to="/original" @click="handleClick()">Original</router-link>
            <div class="language-button-wrapper" v-if="isMobile">
                <button class="language-button" :class="{ active: activeLang === 'kr' }" @click="changeLang('kr')">KR</button>
                <button class="language-button" :class="{ active: activeLang === 'en' }" @click="changeLang('en')">EN</button>
            </div>
        </div>
    </nav>
</template>

<script>
import Widget from '@/components/Widget.vue';

export default {
    name: 'AppNavigation',
    props: {
        isMobile: Boolean,
        isMain: Boolean,
    },
    components: {
        Widget,
    },
    data() {
        return {
            activeLang: sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'kr',
            isPopupOpen: false,
        };
    },
    methods: {
        handleClick() {
            if (this.isMobile) {
                this.emitter.emit('click', 1);

                this.$refs.toggleButton.classList.remove('active');
                this.$refs.toggleMenu.classList.remove('active');
            }
        },
        changeLang(lang) {
            this.activeLang = lang;

            sessionStorage.setItem('lang', lang);
            this.emitter.emit('lang', this.activeLang);
        },
        notifyClick() {
            this.emitter.emit('click', 0);
            document.querySelector('html').removeAttribute('data-page');
        },
        handleMobileMenu() {
            if (this.$refs.toggleButton.classList.contains('active')) {
                this.$refs.toggleButton.classList.remove('active');
                this.$refs.toggleMenu.classList.remove('active');
            } else {
                this.$refs.toggleButton.classList.add('active');
                this.$refs.toggleMenu.classList.add('active');
            }
        },
        receivePopupOpen(value) {
            this.isPopupOpen = value;
        },
        handleDisplay() {
            if (this.isMobile) {
                if (this.isMain) {
                    if (this.isPopupOpen) {
                        return 'none';
                    } else {
                        return 'block';
                    }
                } else {
                    if (this.isPopupOpen) {
                        return 'block';
                    } else {
                        return 'none';
                    }
                }
            } else {
                return 'block';
            }
        },
    },
    beforeMount() {
        this.emitter.on('popupOpen', this.receivePopupOpen);
    },
    mounted() {
        this.emitter.emit('lang', this.activeLang);
    },
};
</script>

<style lang="scss">
#content {
    .navigation-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid var(--main-color);
        background-color: var(--background-color);
        z-index: 99;
        .navigation-wrapper {
            &.top {
                display: grid;
                grid-template-columns: 2.25rem 1fr 2.25rem;
                column-gap: 0.5rem;
                height: 4rem;
                padding: 0.8rem 1.125rem;
                border-bottom: 1px solid var(--main-color);
                .navigation-logo {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    grid-column: 2/3;
                    width: fit-content;
                    height: 100%;
                    margin: 0.25rem auto 0;
                    overflow: hidden;
                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        filter: var(--filter);
                        transition: filter 0.25s;
                    }
                    .loop {
                        cursor: pointer;
                        animation: slideUp 10s infinite;
                        animation-delay: 2s;
                        @keyframes slideUp {
                            0% {
                                top: 0;
                            }
                            25% {
                                top: 0;
                            }
                            30% {
                                top: -100%;
                            }
                            75% {
                                top: -100%;
                            }
                            80% {
                                top: 0;
                            }
                            100% {
                                top: 0;
                            }
                        }
                    }
                }
                .language-button-wrapper {
                    position: absolute;
                    right: 0.75rem;
                    bottom: 0.625rem;
                    display: flex;
                    align-items: flex-end;
                    gap: 0.25rem;
                    .language-button {
                        font-size: 1.5rem;
                        color: #9a9a9a;
                        cursor: pointer;
                        &.active {
                            color: var(--main-color);
                        }
                    }
                }
                .widget-container {
                    margin: 0.2rem 0;
                }
            }
            &.bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                gap: 1rem;
                max-width: 62.5rem;
                height: 2.5rem;
                margin: 0 auto;
                font-size: 1.75rem;
                overflow-x: auto;
                overflow-y: hidden;
                a {
                    cursor: pointer;
                    white-space: nowrap;
                    &:hover {
                        color: var(--primary-color);
                    }
                }
                .router-link-active {
                    color: var(--primary-color);
                    pointer-events: none;
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    #content {
        .navigation-container {
            background-color: transparent;
        }
        .mobile-toggle-button {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            .open-button {
                position: absolute;
                top: 1.125rem;
                left: 50%;
                width: 8vw;
                height: 1.125rem;
                max-width: 50px;
                transform: translateX(-50%);
                filter: var(--filter);
                transition: filter 0.25s;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzMiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgOC4wODI4OUgzMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjIyNjQiLz4KPHBhdGggZD0iTTAgMUgzMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjIyNjQiLz4KPHBhdGggZD0iTTAgMTUuMTY2SDMyIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuMjI2NCIvPgo8L3N2Zz4K');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            z-index: 99;
            &.active {
                .overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #63636366;
                    backdrop-filter: blur(4px);
                    transition: background-color 0.3s;
                }
            }
        }
        .navigation-container {
            border: 0;
            background-color: transparent;
            .navigation-wrapper {
                &.top {
                    display: block;
                    height: auto;
                    padding: 2.5rem 1.125rem 1.125rem;
                    border: 0;
                    img {
                        width: 100%;
                    }
                }
                &.bottom {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: calc(100% - 3.5rem);
                    max-width: 37.5rem;
                    height: fit-content;
                    margin: 1.125rem auto 0;
                    padding: 1.125rem 1.5rem 1.5rem;
                    border-radius: 1.25rem;
                    background-color: #fff;
                    font-size: 2.5rem;
                    line-height: 1.2;
                    transform: translateX(-100vw);
                    transition: transform 0.3s;
                    box-shadow: 7px 7px 15px 0px rgb(50, 50, 50, 0.3);
                    &.active {
                        transform: translateX(0);
                        z-index: 99;
                    }
                    a {
                        color: #000;
                        &.active {
                            color: #7000ff;
                        }
                    }
                    .language-button-wrapper {
                        display: flex;
                        gap: 0.25rem;
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin-top: 1.125rem;
                        margin-right: 1.125rem;
                        .language-button {
                            font-size: 1.5rem;
                            color: #9a9a9a;
                            cursor: pointer;
                            &.active {
                                color: #000;
                            }
                        }
                    }
                }
            }
        }
        .main-content-wrapper .navigation-container {
            display: none;
        }
    }
}
@media (max-width: 768px) {
    #content {
        .mobile-toggle-button {
            img {
                top: 0.75rem;
            }
        }
        .navigation-container {
            .navigation-wrapper {
                &.top {
                    padding: 1.5rem 0.75rem 0.75rem;
                }
                &.bottom {
                    margin: 0.75rem auto 0;
                    padding: 0.875rem 1.125rem 1.125rem;
                    font-size: 2rem;
                    line-height: 1.1;
                    row-gap: 0.4rem;
                }
            }
        }
    }
}
@media (max-width: 480px) {
    #content {
        .navigation-container {
            .navigation-wrapper {
                &.bottom {
                    font-size: 1.875rem;
                    .language-button-wrapper {
                        position: relative;
                    }
                }
            }
        }
    }
}
@media (max-width: 360px) {
    #content {
        .navigation-container {
            .navigation-wrapper {
                &.bottom {
                    padding: 0.75rem 1.125rem;
                    font-size: 1.75rem;
                    a {
                        white-space: pre-wrap;
                    }
                    .language-button-wrapper {
                        .language-button {
                            font-size: 1.25rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
