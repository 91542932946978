<template>
    <div v-if="!isMobile" class="widget-container" @click="handleClick" ref="widgetContainer">
        <div class="widget-inner" ref="widgetInner" @click="handleClickInner">
            <video ref="videoPlayer" playsinline muted loop>
                <source src="@/assets/video/popup-motion.mp4" type="video/mp4" />
            </video>
        </div>
    </div>
    <div v-else class="mobile-video-container">
        <router-link :to="'/skin-collaboration'" @click="notifyClick">
            <v-stage ref="stage" :config="{ x: 0, y: 0, width: vw * 100, height: vw * 100 }">
                <v-layer ref="layer">
                    <v-image :config="{ image: src, x: 0, y: 0, width: vw * 100, height: vw * 100 }" />
                </v-layer>
            </v-stage>
        </router-link>
    </div>
</template>

<script>
import parseAPNG from 'apng-js';
import Konva from 'konva';

export default {
    name: 'AppWidget',
    props: {
        isMobile: Boolean,
        vh: Number,
        vw: Number,
    },
    data() {
        return {
            src: null,
            animation: null,
            currentPlayer: null,
        };
    },
    methods: {
        async loadAPNGImage() {
            const pngPath = window.innerWidth > 768 ? require(`@/assets/img/apng/main_animation_1000.png`) : require(`@/assets/img/apng/main_animation_700.png`);

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!this.$refs.layer) return;
            const layer = this.$refs.layer.getNode();

            try {
                const buffer = await this.getImgBuffer(pngPath);
                const apng = parseAPNG(buffer);
                const START_FRAME = Math.floor(Math.random() * Math.floor(apng.frames.length));

                const player = await apng.getPlayer(ctx);

                canvas.width = apng.width;
                canvas.height = apng.height;

                this.currentPlayer = player;

                for (let i = 0; i < START_FRAME; i++) {
                    player.renderNextFrame();
                }

                player.play();

                this.src = canvas;
                this.animation = new Konva.Animation(() => {}, layer);
                this.animation.start();
            } catch (error) {
                console.error('Error loading the image:', error);
            }
        },
        async getImgBuffer(url) {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (err) => reject(err);
            });
        },
        handleClick(e) {
            if (this.$refs.widgetContainer == e.target) {
                // 위젯 영역 바깥 클릭시
                if (this.$refs.widgetInner.classList.contains('open') || this.$refs.widgetInner.classList.contains('fullsize')) {
                    this.$refs.widgetInner.classList.remove('open');
                    this.$refs.widgetInner.classList.remove('fullsize');
                } else {
                    this.$refs.widgetInner.classList.add('open');
                    this.$refs.videoPlayer.play();
                }
            } else {
                // 위젯 영역 안쪽 클릭시
                if (this.$refs.widgetInner.classList.contains('open')) {
                    this.$refs.widgetInner.classList.remove('open');
                    this.$refs.widgetInner.classList.add('fullsize');
                } else if (this.$refs.widgetInner.classList.contains('fullsize')) {
                    this.$refs.widgetInner.classList.remove('fullsize');
                    this.$refs.widgetInner.classList.add('open');
                }
            }
        },
        notifyClick() {
            this.emitter.emit('click', 1);
        },
    },
    mounted() {
        this.loadAPNGImage();
    },
};
</script>

<style lang="scss">
#content {
    .widget-container {
        position: relative;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        &:is(:root[data-ratio='non-retina'] .widget-container) {
            &::before,
            &::after {
                height: 1px;
            }
        }
        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 1.5px;
            left: 0;
            top: calc(50% - 1px);
            background-color: var(--main-color);
            -webkit-transform-origin: 50%;
            transform-origin: 50%;
            transform: translateY(-1px);
            -webkit-transform: translateY(-1px);
        }
        &::before {
            transform: rotate(90deg);
        }
        .widget-inner {
            position: absolute;
            top: calc(1rem - 1px);
            left: calc(1.125rem - 1px);
            width: 0;
            height: 0;
            overflow: hidden;
            background-color: #fff;
            transition: all 0.3s;
            border-radius: 1.25rem;
            z-index: 100;
            box-shadow: 7px 7px 15px 0px rgb(50 50 50 / 60%);
            &.open {
                width: 50vw;
                height: 35vw;
                transition: all 0.3s;
            }
            &.fullsize {
                width: calc(100vw - 2.25rem);
                height: calc(100 * var(--vh) - 2.25rem);
                transition: all 0.3s;
            }
            video {
                display: block;
                width: 100%;
                max-width: 1200px;
                height: 100%;
                margin: 0 auto;
                object-fit: contain;
                object-position: center;
                transform: scale(1.2);
            }
        }
        &:has(.widget-inner.open),
        &:has(.widget-inner.fullsize) {
            position: absolute;
            top: 0;
            width: 100vw;
            left: 0;
            height: 100vh;
            z-index: 1;
            &::before,
            &::after {
                display: none;
            }
        }
    }
}
@media (max-width: 1024px) {
    #content {
        .mobile-video-container {
            width: 100%;
            height: 100%;
            a {
                display: flex;
                height: 100%;
                & > div {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: calc(100 * var(--vh) - ((100vw - 2.25rem) / 3.8043478261 + 3.625rem));
                    margin: auto auto 1.125rem;
                    z-index: -1;
                    .konvajs-content {
                        width: 100% !important;
                        height: 100% !important;
                    }
                    canvas {
                        width: 100% !important;
                        height: 100% !important;
                        transform: scale(1.3);
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
</style>
