import { createStore } from 'vuex';

export default createStore({
    state: {
        originalPosts: [],
        transformedPosts: [],
    },
    mutations: {
        setOriginalPosts(state, payload) {
            state.originalPosts = payload;
        },
        setTransformedPosts(state, payload) {
            state.transformedPosts = payload;
        },
    },
    actions: {
        async fetchPosts(context) {
            const server = 'https://vibrantwifiproject.com/wp';
            const perPage = '100';
            const postType = 'posts';

            try {
                const response = await fetch(`${server}/wp-json/wp/v2/${postType}?per_page=${perPage}`);
                const posts = await response.json();
                const transformedPosts = [];

                posts.forEach((post) => {
                    post.acf['image-slide'].forEach((thumbnail, i) => {
                        transformedPosts.push({
                            id: post.id,
                            thumbnailIndex: i,
                            slug: post.slug,
                            thumbnail_video: thumbnail.thumbnail_video,
                            thumbnail_image_mobile: thumbnail.mobile_thumbnail,
                            thumbnail_image: thumbnail.detail,
                            title: post.title.rendered,
                            originalIndex: posts.indexOf(post),
                        });
                    });
                });

                context.commit('setOriginalPosts', posts);
                context.commit('setTransformedPosts', transformedPosts);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        },
    },
});
