<template>
    <div class="toggle-button-wrapper">
        <router-link to="/skin-collaboration/artist" :class="{ 'router-link-active': isArtistActive }">Artist</router-link>
        <router-link to="/skin-collaboration">Works</router-link>
    </div>
</template>

<script>
export default {
    name: 'AppToggleView',
    data() {
        return {
            isArtistActive: this.$route.path.startsWith('/skin-collaboration/'),
        };
    },
};
</script>

<style lang="scss">
#content {
    .toggle-button-wrapper {
        position: absolute;
        top: 6.5rem;
        right: 1.125rem;
        display: flex;
        gap: 3rem;
        z-index: 10;
        a {
            position: relative;
            cursor: pointer;
            color: #c6c6c6;
            font-size: 1.5rem;
            line-height: 5.25rem;
            &:first-child::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 100%;
                width: 2rem;
                height: 100%;
                margin-left: 0.4rem;
                transform: rotate(180deg);
                mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDIxIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMC41IDNMMS41IDMiIHN0cm9rZT0iIzcwMDBGRiIvPgo8Y2lyY2xlIGN4PSIyLjUiIGN5PSIzIiByPSIyLjUiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMi41IDMpIiBmaWxsPSIjNzAwMEZGIi8+Cjwvc3ZnPgo=');
                mask-repeat: no-repeat;
                mask-size: contain;
                mask-position: center;
                -webkit-mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDIxIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMC41IDNMMS41IDMiIHN0cm9rZT0iIzcwMDBGRiIvPgo8Y2lyY2xlIGN4PSIyLjUiIGN5PSIzIiByPSIyLjUiIHRyYW5zZm9ybT0icm90YXRlKC0xODAgMi41IDMpIiBmaWxsPSIjNzAwMEZGIi8+Cjwvc3ZnPgo=');
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: contain;
                -webkit-mask-position: center;
                background-color: var(--primary-color);
                cursor: initial;
                pointer-events: none;
            }
            &.router-link-active {
                pointer-events: none;
                color: var(--primary-color);
                &:first-child::after {
                    transform: rotate(0);
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    #content {
        .toggle-button-wrapper {
            top: calc((100vw - 2.25rem) / (1400 / 368) + 3.625rem);
            left: 1.125rem;
            right: 1.125rem;
            justify-content: center;
            gap: 0.6rem;
            transform: translateY(0);
            transition: transform 0.3s, opacity 0.2s;
            overflow: hidden;
            opacity: 0;
            animation-duration: 0.55s;
            animation-delay: 0s;
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
            a {
                color: #000;
                background-color: #737373;
                border-radius: 1.5rem;
                font-size: 1.5rem;
                line-height: initial;
                padding: 0.1rem 1.5rem;
                &.router-link-active {
                    color: #000;
                    background-color: #e6e6e6;
                }
                &::after {
                    display: none !important;
                }
            }
        }
        .content-show {
            .toggle-button-wrapper {
                opacity: 1;
                &.scrolling {
                    transform: translateY(-100%);
                    opacity: 0 !important;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    #content {
        .toggle-button-wrapper {
            top: calc((100vw - 1.5rem) / (1400 / 368) + 2.25rem);
            left: 0.75rem;
            right: 0.75rem;
            a {
                padding: 0.05rem 1.5rem;
                font-size: 1.25rem;
            }
        }
    }
}
</style>
