<template>
    <div class="content-container" style="padding-bottom: 0.75rem">
        <div class="grid-box" ref="gridBox" @scroll="updateActivePosts">
            <div v-for="(post, index) in posts" :key="`${post.id}_${post.thumbnailIndex}`" :ref="`post_${post.id}_${post.thumbnailIndex}`" class="post-item" :class="[post.cssClass, { active: activePosts.includes(`${post.id}_${post.thumbnailIndex}`) }]" :style="{ width: post.width + 'vh' }" @click="openPopup(post, index)" @mouseover="playVideo(index)" @mouseleave="reverseVideo(index)">
                <router-link :to="'/skin-collaboration/' + post.slug" class="item-inner">
                    <div class="thumbnail-wrapper">
                        <img v-if="isMobile" :src="post.thumbnail_image_mobile" />
                        <video v-else preload="auto" playsinline muted ref="videoElement" :poster="post.thumbnail_image">
                            <source :src="post.thumbnail_video" type="video/mp4" />
                        </video>
                    </div>
                    <strong class="post-title">{{ post.title }}</strong>
                </router-link>
            </div>
        </div>
        <div v-if="!isMobile" class="auto-scroller left" @mouseover="startScrolling('left')" @mouseleave="stopScrolling()" :class="{ disabled: isScrollLeftDisabled }"></div>
        <div v-if="!isMobile" class="auto-scroller right" @mouseover="startScrolling('right')" @mouseleave="stopScrolling()" :class="{ disabled: isScrollRightDisabled }"></div>
    </div>
    <div class="popup-wrapper" :class="{ open: isPopupOpen }" @click="handleClick" ref="popupWrapper">
        <post-popup :post="currentPost" @prev-post="goToPrevPost" @next-post="goToNextPost" @close-popup="closePopup" />
    </div>
    <toggle-view :class="{ scrolling: mobileScrolling }" />
</template>

<script>
import { mapState } from 'vuex';

import PostPopup from '@/components/PostPopup.vue';
import ToggleView from '@/components/ToggleView.vue';

export default {
    name: 'AppSkinCollaboration',
    components: { PostPopup, ToggleView },
    computed: {
        ...mapState(['originalPosts']),
        ...mapState(['transformedPosts']),
    },
    data() {
        return {
            posts: [],
            currentPost: null,
            currentOriginalPostIndex: null,
            activePosts: [],
            targetScrollX: 0,
            currentScrollX: 0,
            isPopupOpen: false,
            isScrolling: false,
            scrollAnimationFrameID: null,
            lastScroll: 0,
            scrollCount: 0,
            scrollLimit: 100,
            mobileScrolling: false,
            isMobile: false,
            videoState: [],
            postType: 'posts',
            scrollAnimationID: null,
            isScrollLeftDisabled: true,
            isScrollRightDisabled: false,
        };
    },
    methods: {
        startScrolling(direction) {
            const scrollAmount = 100;
            const scrollFunction = () => {
                if (direction === 'left') {
                    this.targetScrollX = this.currentScrollX - scrollAmount;
                } else {
                    this.targetScrollX = this.currentScrollX + scrollAmount;
                }
                if (!this.isScrolling) {
                    this.animateScroll();
                }
                this.scrollAnimationID = requestAnimationFrame(scrollFunction);
            };
            scrollFunction();
        },
        stopScrolling() {
            cancelAnimationFrame(this.scrollAnimationID);
        },
        playVideo(index) {
            if (this.isMobile) return;

            const video = this.$refs.videoElement[index];
            if (video.readyState < 4) {
                video.addEventListener('loadeddata', () => {
                    this.handlePlayVideo(index);
                });
            } else {
                this.handlePlayVideo(index);
            }
        },
        handlePlayVideo(index) {
            if (this.videoState[index] === 'reversing') {
                this.videoState[index] = 'stopped';
            }
            this.videoState[index] = 'playing';
            this.$refs.videoElement[index].play();
        },
        reverseVideo(index) {
            if (this.isMobile) return;

            const $this = this;
            const video = this.$refs.videoElement[index];
            if (this.videoState[index] === 'reversing') {
                return;
            }

            this.videoState[index] = 'reversing';

            function reverseFrame() {
                if (video.currentTime <= 0 || $this.videoState[index] !== 'reversing') {
                    $this.videoState[index] = 'stopped';
                    return;
                }
                video.currentTime -= 0.1;
                setTimeout(reverseFrame, 100);
            }

            video.pause();
            reverseFrame();
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth <= 1024;
        },
        handleClick(e) {
            if (e.target == this.$refs.popupWrapper) {
                this.closePopup();
            }
        },
        handleKeydown(e) {
            if (e.key === 'Escape' || e.keyCode === 27) {
                if (document.documentElement.classList.contains('with-fancybox')) return;
                this.closePopup();
            }
        },
        openPopup(post) {
            this.currentPost = post;
            this.currentOriginalPostIndex = post.originalIndex; // 원본 포스트 배열의 인덱스 사용
            this.isPopupOpen = true;

            history.pushState({}, '', `/skin-collaboration/${post.slug}`);

            this.emitter.emit('popupOpen', this.isPopupOpen);
        },
        closePopup() {
            this.$refs.popupWrapper.style = 'animation: OpacityToZero 0.5s forwards';
            document.querySelector('.popup').style = 'animation: slideFromTop 0.5s forwards !important';
            setTimeout(() => {
                this.isPopupOpen = false;
                document.querySelector('.popup').style.animation = 'unset';
                this.$refs.popupWrapper.style = 'unset';
                this.emitter.emit('popupOpen', this.isPopupOpen);
            }, 500);
            history.pushState({}, '', '/skin-collaboration');

            this.$refs.popupWrapper.scrollTo(0, 0);
        },
        goToPrevPost() {
            if (this.currentOriginalPostIndex > 0) {
                this.currentOriginalPostIndex--;
            } else {
                // 마지막 포스트로 이동
                this.currentOriginalPostIndex = this.originalPosts.length - 1;
            }

            const newPost = this.originalPosts[this.currentOriginalPostIndex];
            const correspondingTransformedPost = this.posts.find((p) => p.originalIndex === this.currentOriginalPostIndex);

            // 게시물이 바뀌면 thumbnail active 클래스를 초기화 해줘야함
            this.currentPost = {
                ...correspondingTransformedPost,
                thumbnailIndex: 0,
            };
            history.pushState({}, '', `/skin-collaboration/${newPost.slug}`);

            this.$refs.popupWrapper.scrollTo(0, 0);
        },
        goToNextPost() {
            if (this.currentOriginalPostIndex < this.originalPosts.length - 1) {
                this.currentOriginalPostIndex++;
            } else {
                // 첫 번째 포스트로 이동
                this.currentOriginalPostIndex = 0;
            }

            const newPost = this.originalPosts[this.currentOriginalPostIndex];
            const correspondingTransformedPost = this.posts.find((p) => p.originalIndex === this.currentOriginalPostIndex);

            this.currentPost = {
                ...correspondingTransformedPost,
                thumbnailIndex: 0,
            };
            history.pushState({}, '', `/skin-collaboration/${newPost.slug}`);

            this.$refs.popupWrapper.scrollTo(0, 0);
        },
        handleToggleViewByScroll(scroll) {
            let delta = scroll - this.lastScroll;

            if (delta > 0 && this.scrollCount < 0) this.scrollCount = 0;
            if (delta < 0 && this.scrollCount > 0) this.scrollCount = 0;
            this.scrollCount = Math.min(Math.max(this.scrollCount + delta, -this.scrollLimit), this.scrollLimit);

            if (this.scrollCount === this.scrollLimit && !this.mobileScrolling) {
                this.mobileScrolling = true;
            } else if (this.scrollCount === -this.scrollLimit && this.mobileScrolling) {
                this.mobileScrolling = false;
            }

            this.lastScroll = scroll;
        },
        async getPosts() {
            if (this.originalPosts.length === 0) {
                await this.$store.dispatch('fetchPosts');
            }

            const transformedPosts = this.transformedPosts;

            // 포스트 배열 suffle
            for (let i = transformedPosts.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [transformedPosts[i], transformedPosts[j]] = [transformedPosts[j], transformedPosts[i]];
            }

            this.posts = this.assignClassesToPosts(transformedPosts);
        },
        handleScroll(e) {
            // 가로 스크롤
            e.preventDefault();

            const maxScrollX = this.$refs.gridBox.scrollWidth - this.$refs.gridBox.clientWidth;

            if (e.deltaY) {
                this.targetScrollX = Math.min(Math.max(this.targetScrollX + e.deltaY, 0), maxScrollX);
                if (!this.isScrolling) {
                    this.animateScroll();
                }
            }
        },
        animateScroll() {
            this.isScrolling = true;

            const difference = this.targetScrollX - this.currentScrollX;
            const toMove = difference * 0.1;

            if (Math.abs(difference) > 1) {
                this.currentScrollX += toMove;
                this.$refs.gridBox.scrollLeft = this.currentScrollX;
                this.scrollAnimationFrameID = requestAnimationFrame(this.animateScroll);
            } else {
                this.currentScrollX = this.targetScrollX;
                this.$refs.gridBox.scrollLeft = this.targetScrollX;
                this.isScrolling = false;
            }
        },
        updateScrollListener() {
            if (!this.isMobile && !this.isScrollListenerAdded) {
                this.$refs.gridBox.addEventListener('wheel', this.handleScroll, { passive: false });
                this.isScrollListenerAdded = true;
            } else if (this.isMobile && this.isScrollListenerAdded) {
                this.$refs.gridBox.removeEventListener('wheel', this.handleScroll);
                this.isScrollListenerAdded = false;
            }
        },
        debounce(func, wait, immediate) {
            let timeout;
            return function () {
                const context = this,
                    args = arguments;
                const later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                const callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },
        generateRandomWidth(cssClass) {
            const ranges = {
                'third-row': { min: 20, max: 35, step: 5 },
                'half-row': { min: 30, max: 45, step: 5 },
                'full-row': { min: 45, max: 60, step: 5 },
            };

            const range = ranges[cssClass];
            const randomValue = Math.floor(Math.random() * ((range.max - range.min) / range.step + 1)) * range.step + range.min;

            return randomValue;
        },
        assignClassesToPosts(posts) {
            const classGroups = ['third-row', 'full-row', 'half-row'];
            const classCounts = {
                'third-row': 3,
                'full-row': 1,
                'half-row': 2,
            };

            let currentClassGroup, currentCount, previousClassGroup;
            let assignedClass;
            let currentWidth;

            posts.forEach((post) => {
                // random row 클래스
                if (!currentClassGroup || currentCount >= classCounts[currentClassGroup]) {
                    const availableClassGroups = classGroups.filter((group) => group !== previousClassGroup);

                    const randomGroupIndex = Math.floor(Math.random() * availableClassGroups.length);
                    currentClassGroup = availableClassGroups[randomGroupIndex];
                    currentCount = 1;

                    previousClassGroup = currentClassGroup;

                    // 각 클래스 그룹에 random width
                    currentWidth = this.generateRandomWidth(currentClassGroup);
                } else {
                    currentCount++;
                }

                if (currentClassGroup === 'third-row') {
                    assignedClass = `${currentClassGroup}-${currentCount}`;
                } else if (currentClassGroup === 'full-row') {
                    assignedClass = currentClassGroup;
                } else if (currentClassGroup === 'half-row') {
                    assignedClass = `${currentClassGroup}-${currentCount}`;
                }

                post.cssClass = assignedClass; // 포스트에 class 값 부여
                post.width = currentWidth; // 포스트에 width 값 부여
            });

            return posts;
        },
        updateActivePosts() {
            // scroller active
            const gridBox = this.$refs.gridBox;
            this.isScrollLeftDisabled = gridBox.scrollLeft === 0;
            this.isScrollRightDisabled = gridBox.scrollLeft >= gridBox.scrollWidth - gridBox.clientWidth;
            this.$nextTick(() => {
                const activePostDelays = {
                    'third-row': [],
                    'half-row': [],
                    'full-row': [],
                };
                const delayStep = 0.1;
                this.posts.forEach((post) => {
                    // post-item 열이 뷰포트에 들어올 때 active 클래스 추가해서 keyframes 실행
                    const element = this.$refs[`post_${post.id}_${post.thumbnailIndex}`][0];
                    if (!element) return;
                    const rect = element.getBoundingClientRect();
                    const combinedKey = `${post.id}_${post.thumbnailIndex}`;
                    const rowClass = post.cssClass.split('-')[0];
                    if (!this.isMobile) {
                        if (rect.left >= 0 && rect.left <= window.innerWidth) {
                            if (!this.activePosts.includes(combinedKey)) {
                                this.activePosts.push(combinedKey);
                                if (!activePostDelays[rowClass]) {
                                    activePostDelays[rowClass] = [];
                                }
                                // active post의 열별로 animation delay 설정
                                const currentIndex = activePostDelays[rowClass].length;
                                const animationDelay = currentIndex * delayStep;
                                element.style.animationDelay = `${animationDelay}s`;
                                activePostDelays[rowClass].push(animationDelay);
                            }
                        }
                    } else {
                        this.handleToggleViewByScroll(this.$refs.gridBox.scrollTop);
                        if (rect.top <= window.innerHeight) {
                            if (!this.activePosts.includes(combinedKey)) {
                                this.activePosts.push(combinedKey);
                            }
                        }
                    }
                });
                3;
            });
        },
    },
    mounted() {
        this.getPosts();
        this.checkScreenSize();
        this.updateScrollListener();

        if (window.location.pathname !== '/') {
            document.querySelector('html').dataset.page = 'skin-collaboration';
        }

        document.addEventListener('keydown', this.handleKeydown);
        window.addEventListener('resize', this.checkScreenSize);
    },
    updated() {
        document.querySelector('.swiper-wrapper').addEventListener('transitionend', this.updateActivePosts);
    },
    beforeUnmount() {
        if (this.scrollAnimationFrameID !== null) {
            cancelAnimationFrame(this.scrollAnimationFrameID);
        }

        window.removeEventListener('resize', this.checkScreenSize);
        this.$refs.gridBox.removeEventListener('wheel', this.handleScroll);
        document.removeEventListener('keydown', this.handleKeydown);
        document.querySelector('.swiper-wrapper').removeEventListener('transitionend', this.updateActivePosts);
    },
};
</script>

<style lang="scss">
@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slideFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
#content {
    .grid-box {
        display: grid;
        justify-content: start;
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 1.25rem;
        grid-row-gap: 0.75rem;
        height: 100%;
        margin: 0 -1.125rem;
        padding: 0 1.125rem;
        overflow-x: auto;
        .post-item {
            display: flex;
            font-size: min(2.75vh, 1.5rem);
            opacity: 0;
            animation-duration: 0.55s;
            animation-delay: 0s;
            animation-fill-mode: both;
            animation-timing-function: ease-in-out;
            transition: width 0.25s;
            cursor: pointer;
            &.active {
                opacity: 1;
                animation-name: slideFromRight;
            }
            &:hover {
                img {
                    opacity: 0.7;
                    transform: scale(1.05);
                }
                .post-title {
                    &::after,
                    &::before {
                        display: none !important;
                    }
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTUiIGN5PSIxNSIgcj0iMTUiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0yNS4zNDk1IDE0Ljc1ODhMNC42NjY3NSAxNC43NTg4IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik0xOC4xMDgyIDcuNTI2NjdMMjUuMzQ5NCAxNC43NTg3TDE4LjEwODIgMjIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPC9zdmc+Cg==');
                    background-repeat: no-repeat;
                    background-position: top 0.25em right 0.5rem;
                    background-size: 0.75em;
                }
            }
            .item-inner {
                display: grid;
                grid-template-rows: 1fr auto;
                width: 100%;
                height: 100%;
                overflow: hidden;
                pointer-events: none;
                .thumbnail-wrapper {
                    border-radius: 1.25rem;
                    background-color: #e3e3e3;
                    overflow: hidden;
                    video,
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        transition: transform 0.2s;
                    }
                }
                .post-title {
                    position: relative;
                    display: block;
                    min-height: 2.6rem;
                    padding: 0.3125rem 1.4em 0 0.625rem;
                    line-height: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &:is(:root[data-ratio='non-retina'] .post-title) {
                        &::before,
                        &::after {
                            height: 1px;
                        }
                    }
                    &::before,
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 0.625rem;
                        width: 0.75em;
                        height: 1.5px;
                        top: 0.625em;
                        background-color: var(--main-color);
                        -webkit-transform-origin: 50%;
                        transform-origin: 50%;
                        transform: translateY(-0.5px);
                        -webkit-transform: translateY(-0.5px);
                    }
                    &::before {
                        transform: rotate(90deg);
                    }
                }
            }
            &.third-row-1 {
                grid-row: 1/3;
                .thumbnail-wrapper video {
                    transform: scale(1.2);
                }
            }
            &.third-row-2 {
                grid-row: 3/5;
                .thumbnail-wrapper video {
                    transform: scale(1.2);
                }
            }
            &.third-row-3 {
                grid-row: 5/7;
                .thumbnail-wrapper video {
                    transform: scale(1.2);
                }
            }
            &.half-row-1 {
                grid-row: 1/4;
                font-size: min(3.25vh, 1.875rem);
            }
            &.half-row-2 {
                grid-row: 4/7;
                font-size: min(3.25vh, 1.875rem);
            }
            &.full-row {
                grid-row: 1/7;
                font-size: min(4.25vh, 2.25rem);
            }
        }
    }
    .auto-scroller {
        position: absolute;
        top: 6.5rem;
        right: 0;
        width: 3.5rem;
        height: calc(100% - 6.5rem);
        mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCA0NSAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQyLjY4MjggMTYuOTI0OUwwIDE2LjkyNDkiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMy4wOTU1MyIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik0yNy43Mzg4IDIuMDAwMDRMNDIuNjgyNSAxNi45MjQ3TDI3LjczODggMzEuODY4NSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIzLjA5NTUzIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPC9zdmc+Cg==');
        background-color: #dddddd;
        mask-repeat: no-repeat;
        mask-position: 0 center;
        mask-size: 2.5rem;
        z-index: 10;
        opacity: 1;
        transition: opacity 0.3s, background-color 0.3s;
        cursor: pointer;
        &:hover {
            background-color: #000;
        }
        &.left {
            left: 0;
            mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCA0NiAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMDAwMDkgMTYuOTQzNUw0NS42ODI5IDE2Ljk0MzUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMy4wOTU1MyIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIi8+CjxwYXRoIGQ9Ik0xNy45NDQxIDMxLjg2ODRMMy4wMDAzNyAxNi45NDM3TDE3Ljk0NDEgMS45OTk5NCIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIzLjA5NTUzIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz4KPC9zdmc+Cg==');
            mask-position: 1rem center;
        }
        &.disabled {
            opacity: 0;
        }
    }
}
@media (max-width: 1024px) {
    #content {
        .grid-box {
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            padding-top: calc((100vw - 1.5rem) / 3.8043478261 + 2.25rem + 5rem) !important;
            overflow-x: hidden;
            .post-item {
                width: 100% !important;
                font-size: 1.875rem !important;
                .item-inner {
                    .thumbnail-wrapper {
                        aspect-ratio: 1.777778;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                            background-color: #f9f9f9;
                            transition: opacity 0.3s, transform 0.3s;
                        }
                    }
                    .post-title {
                        min-height: auto;
                        padding: 0.675rem 1.4em 1.5rem 0.625rem;
                        &::after,
                        &::before {
                            display: none !important;
                        }
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iMTEiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xOC41ODk3IDEwLjgyM0wzLjQyMjM2IDEwLjgyMyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8cGF0aCBkPSJNMTMuMjc5MyA1LjUxOTUyTDE4LjU4OTYgMTAuODIzTDEzLjI3OTMgMTYuMTMzMyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPgo8L3N2Zz4K');
                        background-repeat: no-repeat;
                        background-position: top 0.5em right 0.75rem;
                        background-size: 0.85em;
                    }
                }
                &.active {
                    opacity: 1;
                    animation-name: slideFromBottom;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    #content {
        .grid-box {
            grid-column-gap: 0.75rem;
            grid-row-gap: 0.5rem;
            margin: 0 -0.75rem;
            padding-top: calc((100vw - 1.5rem) / 3.8043478261 + 2.25rem + 3.25rem) !important;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
            .post-item {
                font-size: 1.75rem !important;
                .item-inner {
                    .thumbnail-wrapper {
                        border-radius: 0.8125rem;
                    }
                    .post-title {
                        min-height: 2.2rem;
                    }
                }
            }
        }
    }
}
@media (max-width: 480px) {
    #content {
        .grid-box {
            .post-item {
                font-size: 1.5rem !important;
            }
        }
    }
}
</style>
