<template>
    <artist :clickedArtistSlug="clickedArtistSlug" />
</template>

<script>
import Artist from '@/views/Artist.vue';

export default {
    name: 'AppPost',
    components: { Artist },
    data() {
        return {
            clickedArtistSlug: this.$route.params.slug,
        };
    },
};
</script>
